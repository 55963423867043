/*! Generated by Font Squirrel (https://www.fontsquirrel.com) on March 27, 2020 */

@font-face {
  font-family: "Wotfard";
  src: url("/fonts/wotfard/wotfard-thin-webfont.eot");
  src: url("/fonts/wotfard/wotfard-thin-webfont.eot?#iefix")
      format("embedded-opentype"),
    url("/fonts/wotfard/wotfard-thin-webfont.woff2") format("woff2"),
    url("/fonts/wotfard/wotfard-thin-webfont.ttf") format("truetype");
  font-weight: 100;
  font-style: normal;
}

@font-face {
  font-family: "Wotfard";
  src: url("/fonts/wotfard/wotfard-extralight-webfont.eot");
  src: url("/fonts/wotfard/wotfard-extralight-webfont.eot?#iefix")
      format("embedded-opentype"),
    url("/fonts/wotfard/wotfard-extralight-webfont.woff2") format("woff2"),
    url("/fonts/wotfard/wotfard-extralight-webfont.ttf") format("truetype");
  font-weight: 200;
  font-style: normal;
}

@font-face {
  font-family: "Wotfard";
  src: url("/fonts/wotfard/wotfard-light-webfont.eot");
  src: url("/fonts/wotfard/wotfard-light-webfont.eot?#iefix")
      format("embedded-opentype"),
    url("/fonts/wotfard/wotfard-light-webfont.woff2") format("woff2"),
    url("/fonts/wotfard/wotfard-light-webfont.ttf") format("truetype");
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: "Wotfard";
  src: url("/fonts/wotfard/wotfard-regular-webfont.eot");
  src: url("/fonts/wotfard/wotfard-regular-webfont.eot?#iefix")
      format("embedded-opentype"),
    url("/fonts/wotfard/wotfard-regular-webfont.woff2") format("woff2"),
    url("/fonts/wotfard/wotfard-regular-webfont.ttf") format("truetype");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: "Wotfard";
  src: url("/fonts/wotfard/wotfard-medium-webfont.eot");
  src: url("/fonts/wotfard/wotfard-medium-webfont.eot?#iefix")
      format("embedded-opentype"),
    url("/fonts/wotfard/wotfard-medium-webfont.woff2") format("woff2"),
    url("/fonts/wotfard/wotfard-medium-webfont.ttf") format("truetype");
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: "Wotfard";
  src: url("/fonts/wotfard/wotfard-semibold-webfont.eot");
  src: url("/fonts/wotfard/wotfard-semibold-webfont.eot?#iefix")
      format("embedded-opentype"),
    url("/fonts/wotfard/wotfard-semibold-webfont.woff2") format("woff2"),
    url("/fonts/wotfard/wotfard-semibold-webfont.ttf") format("truetype");
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: "Wotfard";
  src: url("/fonts/wotfard/wotfard-bold-webfont.eot");
  src: url("/fonts/wotfard/wotfard-bold-webfont.eot?#iefix")
      format("embedded-opentype"),
    url("/fonts/wotfard/wotfard-bold-webfont.woff2") format("woff2"),
    url("/fonts/wotfard/wotfard-bold-webfont.ttf") format("truetype");
  font-weight: 700;
  font-style: normal;
}
