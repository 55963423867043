/*! Generated by Font Squirrel (https://www.fontsquirrel.com) on March 27, 2020 */

@font-face {
  font-family: "Wotfard";
  src: url("/fonts/wotfard-italic/wotfard-thinitalic-webfont.eot");
  src: url("/fonts/wotfard-italic/wotfard-thinitalic-webfont.eot?#iefix")
      format("embedded-opentype"),
    url("/fonts/wotfard-italic/wotfard-thinitalic-webfont.woff2")
      format("woff2"),
    url("/fonts/wotfard-italic/wotfard-thinitalic-webfont.ttf")
      format("truetype");
  font-weight: 100;
  font-style: italic;
}

@font-face {
  font-family: "Wotfard";
  src: url("/fonts/wotfard-italic/wotfard-extralightitalic-webfont.eot");
  src: url("/fonts/wotfard-italic/wotfard-extralightitalic-webfont.eot?#iefix")
      format("embedded-opentype"),
    url("/fonts/wotfard-italic/wotfard-extralightitalic-webfont.woff2")
      format("woff2"),
    url("/fonts/wotfard-italic/wotfard-extralightitalic-webfont.ttf")
      format("truetype");
  font-weight: 200;
  font-style: italic;
}

@font-face {
  font-family: "Wotfard";
  src: url("/fonts/wotfard-italic/wotfard-lightitalic-webfont.eot");
  src: url("/fonts/wotfard-italic/wotfard-lightitalic-webfont.eot?#iefix")
      format("embedded-opentype"),
    url("/fonts/wotfard-italic/wotfard-lightitalic-webfont.woff2")
      format("woff2"),
    url("/fonts/wotfard-italic/wotfard-lightitalic-webfont.ttf")
      format("truetype");
  font-weight: 300;
  font-style: italic;
}

@font-face {
  font-family: "Wotfard";
  src: url("/fonts/wotfard-italic/wotfard-regularitalic-webfont.eot");
  src: url("/fonts/wotfard-italic/wotfard-regularitalic-webfont.eot?#iefix")
      format("embedded-opentype"),
    url("/fonts/wotfard-italic/wotfard-regularitalic-webfont.woff2")
      format("woff2"),
    url("/fonts/wotfard-italic/wotfard-regularitalic-webfont.ttf")
      format("truetype");
  font-weight: 400;
  font-style: italic;
}

@font-face {
  font-family: "Wotfard";
  src: url("/fonts/wotfard-italic/wotfard-mediumitalic-webfont.eot");
  src: url("/fonts/wotfard-italic/wotfard-mediumitalic-webfont.eot?#iefix")
      format("embedded-opentype"),
    url("/fonts/wotfard-italic/wotfard-mediumitalic-webfont.woff2")
      format("woff2"),
    url("/fonts/wotfard-italic/wotfard-mediumitalic-webfont.ttf")
      format("truetype");
  font-weight: 500;
  font-style: italic;
}

@font-face {
  font-family: "Wotfard";
  src: url("/fonts/wotfard-italic/wotfard-semibolditalic-webfont.eot");
  src: url("/fonts/wotfard-italic/wotfard-semibolditalic-webfont.eot?#iefix")
      format("embedded-opentype"),
    url("/fonts/wotfard-italic/wotfard-semibolditalic-webfont.woff2")
      format("woff2"),
    url("/fonts/wotfard-italic/wotfard-semibolditalic-webfont.ttf")
      format("truetype");
  font-weight: 600;
  font-style: italic;
}

@font-face {
  font-family: "Wotfard";
  src: url("/fonts/wotfard-italic/wotfard-bolditalic-webfont.eot");
  src: url("/fonts/wotfard-italic/wotfard-bolditalic-webfont.eot?#iefix")
      format("embedded-opentype"),
    url("/fonts/wotfard-italic/wotfard-bolditalic-webfont.woff2")
      format("woff2"),
    url("/fonts/wotfard-italic/wotfard-bolditalic-webfont.ttf")
      format("truetype");
  font-weight: 700;
  font-style: italic;
}
